@use "sass:math";

@import "custom_bootstrap_vars";
@import "bootstrap/scss/bootstrap";
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

$headings-font-family: 'Inter', sans-serif;
$bs-body-font-family: 'Inter', sans-serif;

// text-underline-offset

$body-dark: #000;
$body-light: #f3f3f3;
$body-grey: #777777;
$grey-50: #9d9d9d;
$accent: #66b0ff;
$accent-hover: #2d93ff;
$gradient-dark: #2e2c2f;
$input-dark: #222424;
$white: #fff;

$radius-xl: 2rem;
$radius-md: 1rem;

// animations

.animation{
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

 @-webkit-keyframes slideDownIn {
	 0% {

		 -webkit-transform: translateY(-20px);
	 }
	 100% {

		 -webkit-transform: translateY(0);
	 }

	 0% {
		 -webkit-transform: translateY(-20);
	 }
}

@keyframes slideDownIn {
	0% {
		transform: translateY(-20px);
	}
	100% {
		transform: translateY(0);
	}
	0% {
		transform: translateY(-20px);
	}
}

.slideDownIn {
	-webkit-animation-name: slideDownIn;
	animation-name: slideDownIn;
}

 @-webkit-keyframes slideUpIn {
	 0% {

		 -webkit-transform: translateY(20px);
	 }
	 100% {

		 -webkit-transform: translateY(0);
	 }

	 0% {
		 -webkit-transform: translateY(20px);
	 }
}

@keyframes slideUpIn {
	0% {
		transform: translateY(20px);
	}
	100% {
		transform: translateY(0);
	}
	0% {
		transform: translateY(20px);
	}
}

.slideUpIn {
	-webkit-animation-name: slideUpIn;
	animation-name: slideUpIn;
}





.floating{
	animation-name: floating;
	-webkit-animation-name: floating;

	animation-duration: 2s;
	-webkit-animation-duration: 2s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes floating {
	0% {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(1%);
	}
	100% {
		transform: translateY(0%);
	}
}

@-webkit-keyframes floating {
	0% {
		-webkit-transform: translateY(0%);
	}
	50% {
		-webkit-transform: translateY(1%);
	}
	100% {
		-webkit-transform: translateY(0%);
	}
}

// end animations



.bg-body-dark {
  background-color: $body-dark !important;
}
.text-body-dark {
  color: $body-dark !important;
}
.bg-body-light {
  background-color: $body-light !important;
}
.text-body-light {
  color: $body-light !important;
}
.text-body-grey {
  color: $body-grey !important;
}
.bg-accent {
  background-color: $accent !important;
}
.text-accent {
  color: $accent !important;
}
.bg-gradient-dark {
  background-color: $gradient-dark;
}
.bg-input-dark {
  background-color: $input-dark !important;
}
.border-radius-outline {
  border-radius: $radius-xl;
  border: 1px solid $body-grey;
}
@media screen and ( min-width: 992px ) {
  .border-radius-outline-lg {
    border-radius: $radius-xl;
    border: 1px solid $body-grey;
  }
}

.border-radius {
  border-radius: $radius-xl;
  border: 1px solid transparent;
}
.border-radius-small-outline {
  border-radius: $radius-md;
  border: 1px solid $body-grey;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: rgba(255, 255, 255, 0.15) !important;
}
a {
  color: $body-grey;
  text-decoration: none;
  &:not(.text-decoration-none):not(.btn):not(.nav-link):not(.dropdown-item) {
    border-bottom: 1px solid transparent;
    padding-bottom: .25rem;
  }
  &:hover:not(.text-accent):not(.btn-light) {
    color: $body-light;
    &:not(.text-decoration-none):not(.btn):not(.nav-link):not(.dropdown-item) {
      border-bottom: 1px solid $body-light;
    }
  }
}
.icon-link {
  font-size: 1.75em;
  text-transform: uppercase;
  i {
    line-height: 1;
    //padding: 0.5em;
    //background: $body-dark;
    //width: 2em;
    //height: 2em;
    //border-radius: 50%;
  }
}
.dropdown-toggle {
  border: none;
}
body {
  font-family: $bs-body-font-family;
  font-size: .95rem;
  font-weight: 300;
  color: $body-light;
  background: $gradient-dark;
}

body.homepage {
  background: #000;
}

main {
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(46,44,47,1) 0%, rgba(46,44,47,1) 60%, rgba(0,0,0,1) 100%);
}
//////////////////////////////////////////////////////
// button update
//////////////////////////////////////////////////////

.btn {
  --bs-btn-padding-y: 0.5rem;
  font-size: .85em;
  border-radius: $radius-xl;
  text-transform: uppercase;
}
.btn-primary {
  --bs-btn-bg: #66b0ff;
  --bs-btn-border-color: #66b0ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2d93ff;
  --bs-btn-hover-border-color: #2d93ff;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2d93ff;
  --bs-btn-active-border-color: #2d93ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #66b0ff;
  --bs-btn-disabled-border-color: #66b0ff;
}
.btn-outline-primary {
  --bs-btn-color: #66b0ff;
  --bs-btn-border-color: #66b0ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2d93ff;
  --bs-btn-hover-border-color: #2d93ff;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2d93ff;
  --bs-btn-active-border-color: #2d93ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #66b0ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #66b0ff;
  --bs-gradient: none;
}
// .btn-outline-secondary
//     --bs-btn-color: #f3f3f3
//     --bs-btn-border-color: #f3f3f3
//     --bs-btn-hover-color: #000
//     --bs-btn-hover-bg: #f3f3f3
//     --bs-btn-hover-border-color: #f3f3f3
//     --bs-btn-focus-shadow-rgb: 108,117,125
//     --bs-btn-active-color: #000
//     --bs-btn-active-bg: #f3f3f3
//     --bs-btn-active-border-color: #f3f3f3
//     --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
//     --bs-btn-disabled-color: #f3f3f3
//     --bs-btn-disabled-bg: transparent
//     --bs-btn-disabled-border-color: #f3f3f3
//     --bs-gradient: none


//////////////////////////////////////////////////////
// Primary header navigation
//////////////////////////////////////////////////////

header {
  border-bottom: 1px solid #3B4040;
  .offcanvas-backdrop {
    display: none !important;
  }
}

.offcanvas {
  border-top: 1px solid #3B4040;
}

.header-logo {
  display: block;
  height: 22px;
  width: auto;
  @media screen and ( min-width: 992px ) {
    height: 30px;
  }
}
.navbar-primary {
  .navbar-nav {
    width: 100%;
    .nav-item {
      .nav-link {
        padding: 1rem;
        margin-right: 3rem;
        @media screen and ( min-width: 992px ) {
          padding: 1rem;
          margin-right: 0;
          &.active {
            padding-bottom: .75rem;
            border-bottom: 1px solid $body-light;
          }
        }
      }
    }
  }
  .navbar-primary-sm {
    position: fixed;
    top: 68px;
    left: 0;
    width: 100%;
    height: 100%;
    background: $body-dark;
    @media screen and ( min-width: 768px ) {
      top: 68px;
    }
    .navbar-nav {
      @media screen and ( min-width: 576px ) {
        max-width: 540px;
        margin: 0 auto;
      }
      @media screen and ( min-width: 768px ) {
        max-width: 720px;
      }
    }
    @media screen and ( min-width: 992px ) {
      position: static;
      padding: 0;
      height: auto;
    }
  }
}
.nav-item-has-child {
  position: relative;
  @media screen and ( max-width: 992px ) {
    .chevron {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      padding: 1rem;
      margin-left: -.5rem;
      z-index: 3;
    }
    &::after {
       display: block;
       position: absolute;
       right: 1rem;
       top: 17px;
       content: '\F285';
       font-family: bootstrap-icons !important;
       margin-left: -.5rem;
    }
  }
  .navbar-nav-submenu {
    position: fixed;
    flex-direction: column;
    background-color: $body-dark;
    width: 100%;
    height: 0;
    padding: 1rem;
    overflow: hidden;
    z-index: 99;
    top: 68px;
    opacity: 0;
    visibility: hidden;
    border: 1px solid #3B4040;
    .nav-item {
      .nav-link {
        padding: 1rem;
        border-radius: $radius-xl;
        &:hover {
          background-color: rgba(255,255,255, .15);
        }
      }
      .nav-back-link {
        color: #66B0FF;
        letter-spacing: 0.02em;
      }
    }
    .nav-item-has-child {
      @media screen and ( max-width: 992px ) {
        .chevron {
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          padding: 1rem;
          margin-left: -.5rem;
          z-index: 3;
        }
        &::after {
           display: none;
           position: absolute;
           right: 1rem;
           top: 17px;
           content: '\F285';
           font-family: bootstrap-icons !important;
           margin-left: -.5rem;
        }
      }
    }
    &:target {
      top: 68px;
      left: 0;
      right: 0;
      opacity: 1;
      height: 100%;
      visibility: visible;
      border: none;
      border-top: 1px solid #3B4040;
    }
  }
}
@media screen and ( min-width: 992px ) {
  .nav-item-has-child {
    .navbar-nav-submenu {
      position: absolute;
      display: none;
      top: 50px;
      left: 0;
      width: 280px;
      height: 0;
      padding: 1rem;
      .nav-item {
        .nav-link {
          padding: 0.5rem 1rem;
        }
      }
    }
    &:hover {
      .navbar-nav-submenu {
        opacity: 1;
        transition: all .5s ease-in-out;
        height: auto;
        display: block;
        visibility: visible;
      }
    }
  }
}
.navbar-toggler {
  border: none;
  .bi-list {
    display: none;
    font-size: 1.85rem;
    color: $body-light;
  }
  .bi-x {
    display: block;
    font-size: 1.85rem;
    color: $body-light;
  }
  &.collapsed {
    .bi-x {
      display: none;
    }
    .bi-list {
      display: block;
    }
  }
}
.navbar-dark {
  .nav-link {
    color: $body-light;
  }
//////////////////////////////////////////////////////
// homepage baner full height
//////////////////////////////////////////////////////
}
.hp-primary-banner {
  //background: url('/static/images/title-bg-xxl.jpg') center;
  background-size: cover;
  min-height: calc( 100vh - 88px );
  @media screen and ( min-width: 992px ) {
    height: calc( 100vh - 88px );
    max-height: 1200px;
  }
  @media screen and ( max-width: 992px ) {
    height: calc( 100vh - 70px );
    max-height: 1200px;
  }
  @media screen and ( max-width: 768px ) {
    height: calc( 100vh - 70px );
    max-height: 1200px;

    h5 {
      font-size: 1.05rem;
    }

    .btn {
      font-size: 0.75rem;
    }

    h1 {
      font-size: calc(1.3rem + 4.5vw);
    }
  }
  .hp-primary-banner-overlay {
    background: url('bg.png') no-repeat center bottom / cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .hp-primary-banner-text {
    position: relative;
    z-index: 1;
  }

  .delay-05 {
    animation-delay: 0.5s;
  }

  .delay-15 {
    animation-delay: 1.5s;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
//////////////////////////////////////////////////////
// Apie mus
//////////////////////////////////////////////////////
}
img {
  &[alt="Screen service"] {
    max-width: 160px;
  }
  &[alt="MAX"] {
    max-width: 95px;
  }

//////////////////////////////////////////////////////
// Blog/News
//////////////////////////////////////////////////////
}
.blog-item-wrapper {
  margin-bottom: 1.75rem;
  padding: 1.5rem 1rem;
  @media screen and ( min-width: 992px ) {
    padding: 2.25rem 1.5rem;
  }
  .blog-item-date {
    color: $accent;
    font-weight: bold;
    font-size: 14px;
  }
  .blog-item-title {
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 18px;
  }
  .blog-item-img {
    overflow: hidden;
    img {
      object-fit: cover;
      object-position: center;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  p {
    font-size: 13px;
  }
}
.google-map {
  width: 100%;
  height: 400px;
}
.contact-item {
  margin-bottom: 2rem;
}
.form-control {
  border-radius: 2rem;
  background-color: $input-dark;
  border-color: $body-grey;
  padding: .75rem 1.5rem;
  color: $white;
  font-size: 13px;
  &:focus {
    color: $body-light;
    background-color: #3b4040;
    border-color: $grey-50;
    outline: 0;
    box-shadow: none;
  }
}
.form-label {
  margin-left: 1.5rem;
  font-size: 12px;
}
form {
  .note {
    padding: .475rem 1.5rem;
    border-radius: 100px;
    text-align: center;
  }
}
textarea {
  &:focus,
  &:active {
    background-color: $input-dark;
  }
}
::placeholder {
  color: $body-grey !important;
  opacity: 1;
  font-size: 12px;
}
::-ms-input-placeholder {
  color: $body-grey !important;
}
//////////////////////////////////////////////////////
// services
//////////////////////////////////////////////////////

.subcategories-buttons {
  .btn {
    margin-right: .35rem;
    margin-bottom: 1rem;
    text-transform: none;
  }
}

#gallery {
  min-height: 500px;
  display: block;
}

.product-wrappa {
  border: 1px solid #3B4040;
  border-radius: 1.25rem;

  .swiper {
    .swiper-button-prev {
      left: 0;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: start;
      padding-top: 150px;
      padding-bottom: 150px;
      padding-right: 150px;
      transform: translateY(-150px);
    }
    .swiper-button-next {
      right: 0;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: start;
      padding-top: 150px;
      padding-bottom: 150px;
      padding-left: 150px;
      padding-right: 40px;
      transform: translateY(-150px);
    }
  }
}

.gallery-items-wrapper {
  .gallery-item {
    position: relative;
    background: $gradient-dark;
    border-radius: 1.25rem;
    border: 1px solid #3B4040;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 0 0 $accent;
    //transition: box-shadow .3s ease-out;
    animation-name: fade-in;
    animation-fill-mode: both;
    animation-duration: .5s;
    min-height: 175px;

    img {
      width: auto;
      height: 100%;
      object-fit: cover;
      object-position: center;
      aspect-ratio: 1;
      transform: scale(1) translateX(-15px);
      transition: transform .5s, opacity .3s;
    }

    .gallery-item-thumbnail::after {
      content: "";
      display: block;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .gallery-item-title {
      position: absolute;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 100%;
      height: 35%;
      padding: .75rem 1rem;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(46,44,47,0) 0%, rgba(46,44,47,.7) 100%);
      h6 {
        font-weight: 400;
        font-size: 12px;
      }
    }
    &:hover {
      //box-shadow: 0 0 15px 3px $accent;
      //border-color: $accent;
      img {
        transform: scale(1.1) translateX(-10px);
      }
      .gallery-item-thumbnail::after {
        background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0));
      }
    }
    &.active {
      box-shadow: 0 0 15px 3px $accent;
      border-color: $accent;
      img {
        transform: scale(1.1);
      }
    }
  }
  &.loading {
    opacity: 0.3;
  }
  &.sortable {
    .sortable-chosen {
      .gallery-item {
        border-color: green;
      }
    }
    .gallery-item {
      box-shadow: none;

      &:hover {
        box-shadow: none;
        border-color: red;
      }
    }
  }
}


@media screen and ( max-width: 576px ) and ( min-width: 536px ) {
  .gallery-items-wrapper .gallery-item img {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 0 !important;
    transform: scale(1.1) translateX(0);
  }

  .gallery-item-wrapper .gallery-item:hover img {
    transform: scale(1.1) translateX(0);
  }

  .gallery-item-wrapper .gallery-item.active img {
    transform: scale(1.2) translateX(0);
  }
}

@media screen and ( max-width: 766px ) and ( min-width: 576px ) {
  .gallery-items-wrapper .gallery-item img {
    transform: scale(1.1) translateX(-30px);
  }

  .gallery-item-wrapper .gallery-item:hover img {
    transform: scale(1.2) translateX(-30px);
  }

  .gallery-item-wrapper .gallery-item.active img {
    transform: scale(1.2) translateX(-30px);
  }
}

@media screen and ( max-width: 1200px ) and ( min-width: 992px ) {
  .gallery-items-wrapper .gallery-item img {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 0 !important;
    transform: scale(1.05) translateX(-2px);
  }

  .gallery-item-wrapper .gallery-item:hover img {
    transform: scale(1.05) translateX(-1px);
  }

  .gallery-item-wrapper .gallery-item.active img {
    transform: scale(1.1) translateX(-1px);
  }
}


.swiper {
  overflow: hidden;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  &:not(.product-slider-thumbnail-secondary, .service-slider-thumbnail-secondary) {
    //border: 2px solid $body-grey;
    border-radius: 1.25rem;
  }

  .swiper-zoomer {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #C3C3C3;
    color: #fff;
    text-align: center;
    padding-top: 3px;
    mix-blend-mode: difference;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
  &.product-slider-thumbnail-primary,
  &.product-slider-thumbnail-secondary {
    .swiper-slide {
      img {
        aspect-ratio: 1;
        object-fit: cover;
        object-position: center;
      }

    }
  }
  &.swiper-thumbs {
    border-radius: 0 !important;
    .swiper-slide {
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #C3C3C3;
      &.swiper-slide-thumb-active {
        border: 1px solid $accent;
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    mix-blend-mode: difference;
  }
  &.product-slider-thumbnail-secondary {
    //width: 80%;
    // @media screen and ( min-width: 992px ) {
    //   // max-width: 180px
    //   left: 0;
    //   right: 0;
    //   margin: auto;
    // }
    .swiper-wrapper {
      // @media screen and ( min-width: 992px ) {
      //   justify-content: center;
      // }
      .swiper-slide {
        max-width: 60px;
        border-radius: .75rem;
        overflow: hidden;
        border: 1px solid #C3C3C3;
        opacity: 0.5;
        &.swiper-slide-thumb-active {
          opacity: 1;
        }
      }
    }
  }

  &.service-slider-thumbnail-secondary {
    .swiper-wrapper {
      .swiper-slide {
        opacity: 0.5;
        &.swiper-slide-thumb-active {
          opacity: 1;
        }
      }
    }
  }
  &.product-slider-thumbnail-secondary--xl {
    margin-top: 1rem;
    left: 20px;
    @media screen and (max-width: 992px) {
      margin-top: 1rem;
      left: 0;
    }
    .swiper-wrapper {
      @media screen and (min-width: 992px) {
        justify-content: normal;
      }
    }
  }
}
.product-slider-thumbnail-wrapper {
  position: relative;
}
.icon-link-swiper-close {
  z-index: 2;
  top: 1rem;
  right: 1.25rem;
  position: absolute;
  cursor: pointer;
  @media screen and ( min-width: 768px ) {
    top: 1.25rem;
    right: 2rem;
  }
}
.swiper-button-next,
.swiper-button-prev {
  width: 32px;
  height: 32px;
  background: #fff;
  border: 1px solid $body-grey;
  border-radius: 32px;
  color: $body-dark;
  &::after {
    font-size: 1em;
  }
}
.swiper-button-prev::after {
  margin-right: .15rem;
}
.swiper-button-next::after {
  margin-left: .15rem;
}
.item-descrition-card {
  .item-descrition-card-header {
    margin-bottom: 1rem;
    h2 {
      font-size: 16px;
    }
  }
  .item-properties {
    li {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      span {
        width: 50%;
        flex: 0 0 auto;
        font-weight: 400;
      }
    }
  }
}

.product-slider-thumbnails {
  .swiper {
    width: 70%;
    display: inline-block;
  }

  .swiper-button-next-thumb-small,
  .swiper-button-prev-thumb-small {
    display: inline-block;
    position: relative;
    width: auto;
    top: 22px;
  }

  .swiper-button-disabled {
    opacity: 0;
  }
}


@media (max-width: 768px) {
  // .subcategories-buttons {
  //   overflow-x: auto;
  //   white-space: nowrap;
  //   display: flex;
  //   flex-wrap: nowrap;
  // }
  // .subcategories-buttons .btn {
  //   flex: 0 0 auto;
  // }
  .subcategories-buttons .btn {
    font-size: 0.4rem;
    padding: 5px 10px !important;
    margin: 0 5px 5px 0;
  }
}



.loading-placeholder {
  width: 100%;
  height: 300px;
  text-align: center;
  line-height: 300px;
  background: #151515;
  border-radius: $radius-xl;
  @media (min-width: 768px) {
    height: 550px;
    line-height: 550px;
  }
  @media (min-width: 1400px) {
    height: 470px;
    line-height: 470px;
  }

  &.small {
    height: 570px;
    line-height: 570px;
    @media (min-width: 768px) {
      height: 520px;
      line-height: 520px;
    }
    @media (min-width: 992px) {
      height: 360px;
      line-height: 360px;
    }
    @media (min-width: 1206px) {
      height: 420px;
      line-height: 420px;
    }
    @media (min-width: 1400px) {
      height: 520px;
      line-height: 520px;
    }
  }
}



.item-information {
  overflow: hidden;
  transition: all .5s ease-in-out;
  &.item-show {
    transform: translateY(0);
  }
  &.item-hide {
    transform: translateY(-100%);
  }
}

//
//@media (pointer: coarse) and (hover: none) {
//  .hp-primary-banner video {
//    display: none;
//  }
//}

.service-slider-thumbnail-secondary {
  border: none;
  border-radius: 0;
}


b {
  font-weight: 700 !important;
}


.video-position-center {
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}


.react-player__preview {
  min-height: 235px;
  @media (min-width: 576px) {
    min-height: 406px;
  }
  @media (min-width: 992px) {
    min-height: 547px;
  }
  @media (min-width: 1200px) {
    min-height: 757px;
  }

}


.servicePage {
  .react-player__preview {
    min-height: 235px !important;
    @media (min-width: 576px) {
      min-height: 424px !important;
    }
    @media (min-width: 992px) {
      min-height: 277px !important;
    }
    @media (min-width: 1200px) {
      min-height: 387px !important;
    }
  }
}


.homepage {
  #footer {
    @media (max-width: 993px) {
      display: none;
    }
  }
  .social-icons-mobile {
    position: fixed;
    bottom: 20px;
    width: 100%;
    text-align: center;
    z-index: 99;
    p {
      display: inline-block;
    }
    @media (max-width: 993px) {
        display: block !important;
      }
  }
}


@media (min-width: 992px) {
  .custom-col {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}



.swiper-button-prev, .swiper-button-next {
  background: none;
  border: none;
  color: #fff;
}

.swiper-button-prev {
  left: 0;
  justify-content: start;
  &:after {
    margin-left: 2rem;
  }
}

.swiper-button-next {
  right: 0;
  left: auto;
  justify-content: end;
  &:after {
    margin-right: 2rem;
  }
}


.homepage {
  #footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    font-family: 'Inter', sans-serif;
    font-size: 12px !important;
  }
}


.custom-col {
  flex: 0 0 12.5%;
  max-width: 12.5%;
  padding: 0.5rem;
  margin: 0;


  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) {
    flex: 0 0 25%;
    max-width: 25%;

  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) and (max-width: 991.98px) {
      flex: 0 0 20%;
      max-width: 20%;
  }

   // Medium devices (tablets, 768px and up)
  @media (min-width: 992px) and (max-width: 1200px) {
      flex: 0 0 20%;
      max-width: 20%;
  }
}


.prodspecs {
  width: 100%;
  font-size: 14px;
}

.page-titel {
  position: relative;
}

.zoomed-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  text-align: center;
  .swiper-button-prev, .swiper-button-next {
    width: 40%;
    height: 100%;
    top: 50%;
    transform: translateY(-50%) !important;
  }
  .swiper-button-next {
    justify-content: end !important;
    padding-right: 0px !important;
  }
  img {
    height: 90svh !important;
    width: auto !important;
    cursor: pointer;
  }
}

.modal-dialog {
    min-width: 650px;
  }

@media screen and (max-width: 576px) {
   .modal-dialog {
    min-width: auto;
  }
}

.modal-content {
  background: #222424;
  border: 1px solid #3B4040;
  padding: 1rem;
  .btn-close {
    mix-blend-mode: difference;
  }
  .modal-header {
    border-bottom: 0px;
  }
}



h1 {
  font-size: 26px;
}

h4 {
  font-size: 20px;
}


.categorizedTitle {
  font-size: 20px;
}


@media screen and (max-width: 576px) {
  p {
    font-size: 12px;
  }

  .product-wrappa {
    border: 1px solid #3B4040;
    border-radius: 1.25rem;
  
    .swiper {
      .swiper-button-prev {
        left: 0;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: start;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-right: 50px;
        transform: translateY(-25px);
      }
      .swiper-button-next {
        right: 0;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: start;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 50px;
        padding-right: 40px;
        transform: translateY(-25px);
      }
    }
  }
}


#homeslider {
  width: 100%;
  height: 50vh;
  .swiper-slide {
    opacity: 0;
    background: #034CB480;
    border-radius: 30px;
    padding: 20px;
    min-height: 300px;
    &.swiper-slide-visible {
      opacity: 100;
    }
    &.mob {
      opacity: 100;
    }

    img {
      width: 70px;
      height: 70px;
    }

    h3 {
      font-size: 16px;
      text-transform: uppercase;
      padding: 20px 0 10px 0;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        font-size: 14px
      }
    }
  }

  .swiper {
    perspective: 1000px;
    -webkit-perspective: 1000px;
  }
  
  .swiper-wrapper {
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
  }
  
  .swiper-slide {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    will-change: transform;
  }
  
  @supports (-webkit-backdrop-filter: blur(10px)) {
    .swiper-slide {
      transform: none !important;
      -webkit-transform: none !important;
    }
  }
  
}


.trans3d
	{
		-webkit-transform-style: preserve-3d;
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform-style: preserve-3d;
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform-style:preserve-3d;
		-ms-transform: translate3d(0, 0, 0);
		transform-style:preserve-3d;
		transform: translate3d(0, 0, 0);

		/*-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility:hidden;
		backface-visibility:hidden;*/
	}
	
	#contentContainer
	{
    height: 400px;
		// margin-left:-500px;
		// margin-top:-500px;
		// left:50%;
		// top:50%;
		// width:1000px;
		// height:1000px;
	}
	
	#carouselContainer
	{
		position:absolute;
		margin-left:-500px;
		margin-top:-500px;
		left:50%;
		top:50%;
		width:1000px;
		height:1000px;
	}
	
	.carouselItem
	{
		width:400px;
		height:300px;
		position:absolute;
		left:50%;
		top:50%;
		margin-left:-200px;
		margin-top:-150px;
		visibility:hidden;
    opacity: 0.5 !important;
    filter: blur(10px);
	}

  .carouselItem.leftActive,
  .carouselItem.rightActive {
    opacity: 0.9 !important;
    filter: blur(1px);
  }

  .carouselItem.active {
    opacity: 1 !important;
    filter: blur(0px);
  }
	
	.carouselItemInner
	{
		width:400px;
		height:300px;
		position:absolute;
		background: #1F2F41;
    border-radius: 30px;
    padding: 20px;
    min-height: 300px;
		left:50%;
		top:50%;
		margin-left:-200px;
		margin-top:-150px;
		img {
      width: 70px;
      height: 70px;
    }

    h3 {
      font-size: 16px;
      text-transform: uppercase;
      padding: 20px 0 10px 0;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        font-size: 14px
      }
    }
	}

  .carousel-controls {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 27%;
    justify-content: space-between;
    font-size: 3rem;
    #rotateLeft {
      margin-left: 20%;
    }
    #rotateRight {
      margin-right: 20%;
    } 
  }


.project-wrapper {
  .swiper.product-slider-xl {
    img {
      width: 60%;
      height: auto;
      margin: 0 auto;
    }
  }
}